/* KEEP */
.row, 
[class*="col-"] {
  height: fit-content;
  margin: 1rem 1rem 0 1rem;
  padding: 1rem 1rem 0 1rem;
}

/* END KEEP */

:root {
  --aquamarine-default: #50ffe2;
  --aquamarine-100: #004338;
  --aquamarine-200: #008770;
  --aquamarine-300: #00caa8;
  --aquamarine-400: #0effd7;
  --aquamarine-500: #50ffe2;
  --aquamarine-600: #74ffe8;
  --aquamarine-700: #97ffee;
  --aquamarine-800: #bafff3;
  --aquamarine-900: #dcfff9;

  --orange-crayola-default: #ff6b27;
  --orange-crayola-100: #3b1300;
  --orange-crayola-200: #762500;
  --orange-crayola-300: #b13800;
  --orange-crayola-400: #ed4b00;
  --orange-crayola-500: #ff6b27;
  --orange-crayola-600: #ff8a54;
  --orange-crayola-700: #ffa77e;
  --orange-crayola-800: #ffc4a9;
  --orange-crayola-900: #ffe2d4;

  --wisteria-default: #b4a0e5;
  --wisteria-100: #1d113d;
  --wisteria-200: #3a217a;
  --wisteria-300: #5832b7;
  --wisteria-400: #8262d4;
  --wisteria-500: #b4a0e5;
  --wisteria-600: #c2b2ea;
  --wisteria-700: #d1c5ef;
  --wisteria-800: #e0d9f4;
  --wisteria-900: #f0ecfa;

  --phlox-default: #ca3cff;
  --phlox-100: #2e003f;
  --phlox-200: #5d007e;
  --phlox-300: #8b00be;
  --phlox-400: #ba00fd;
  --phlox-500: #ca3cff;
  --phlox-600: #d664ff;
  --phlox-700: #e08bff;
  --phlox-800: #eab1ff;
  --phlox-900: #f5d8ff;

  --licorice-default: #1e1014;
  --licorice-100: #060304;
  --licorice-200: #0c0608;
  --licorice-300: #120a0c;
  --licorice-400: #180d10;
  --licorice-500: #1e1014;
  --licorice-600: #5a313c;
  --licorice-700: #975165;
  --licorice-800: #be8696;
  --licorice-900: #dfc3cb;
}

/* All reviewed & made to comply with React Bootstrap integration */ 
#sidebarCol {
  min-height:100% !important;
  height: 100%;
  padding: 1rem;
  padding-right:2rem;
  background: linear-gradient(135deg, #F07167 36.46%, #FF9B54 100%);
  color: #ffffff;
  position: relative;
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other elements */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  width: 0%; /* Initial width */
  transition: width 0.3s ease;
}




/* Devin's elements - unreviewed */ 
.font-weight-bold { font-weight: bold; }

html, body, #root, .container-fluid {
  height: calc(100%)
}

body {
  display: block;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #ffffff; 
}

svg {
  margin-right: 8px;
}

.sb-sublist-item a {
  text-decoration: none;
  color: #ffffff;
}

.sb-sublist-item a:hover {
  text-decoration: none;
  color : #A63C00;
}

.sb-sublist-item {
  margin-left:1.5rem;
  margin-top:.5rem;
  padding:0;
}

#teamMembers {
  margin-top:0;
}

a:focus {
  color:#D96320;
  font-weight:800;
}
.level1{
  font-weight:600;
}

.level2 {
  font-weight:500;
}

.level2 a {
  color: white;
}
.level2 a:hover {
    text-decoration: none;
    color : #D96320;
}

.level3 {
  font-weight:400;
  font-size:.9rem;
  color:whitesmoke;
}

.level4 {
  font-weight:400;
  font-size:.8rem;
  text-transform: uppercase;
  letter-spacing: .5;
}

.sidebar-header {
  display: flex;
  justify-content: left;
  padding-top:1em;
}

.sidebar-footer {
  display: block;
  justify-content: left;
  padding:1rem;
  /* originally had
  height: 40px
  */
}

.sidebar-profile-group {
  display: flex;
  padding-bottom:1rem;
}

.profile-sb {
  padding-top:.15rem;
  font-weight:500;
  text-transform:uppercase;
  letter-spacing: .4;
  font-size:.8rem;
}

.profile-sb a {
  text-decoration: none;
  color:lightgoldenrodyellow;
}

.profile-sb a:hover {
  text-decoration: none;
  color : #084298;
}

#profile {
  display: flex;
  width: 100%;
  height:3rem;
  width:3rem;
  margin-right:.5em;
  
}

#profile-name {
  clear:both;
  font-size:.9rem;
  font-weight:500;
  padding-bottom:.25em;
}

.logout a {
  text-decoration:none;
  color:white;
}

.logout a:hover {
  text-decoration:none;
  color:#084298;
}

.card a {
  text-decoration: none;
  color: #000000;
}

.card a:hover {
  text-decoration: none;
  color : #084298;
}

.editPage .card-body, 
.editPage .card-body h1, 
.editPage .card-body h2, 
.editPage .card-body h3,
.editPage .card-body h4 {
  font-family: "Times New Roman", serif;
  font-size: 12pt;
}

.editPage .card-body h1, 
.editPage .card-body h2, 
.editPage .card-body h3 {
  font-weight: bold;
  font-family: "Times New Roman", serif;
  font-size: 12pt;
}

.otherVariables .accordion-button {
  background-color: pink;
}

.otherVariables .accordion-button:not(.collapsed) {
  background-color: #F3F3F6;
}

.loadWarning { 
  font-size: 1rem;
  color: #882D00;
  width: 50%;

}

.pageTitle { 
  font-size: 2.3rem;
  color: #A82A11;
  margin-top:0rem !important;
  margin-bottom: 2rem
}

.pageInstructions { 
  font-size: 1rem;
  margin-top: 1rem;

}
.docListItem { 
  font-weight: 600;
  margin-bottom: .8rem;

}

.docListItem a {
  text-decoration: none !important;
  color: black !important;
}

.docListItem a:hover {
  color:#D96320 !important;

}


.docLinkItem { 
  font-weight: 600;
  margin-bottom: .8rem;

}

#newSection {
  margin-bottom: 2rem;
  color: #D96320 !important;
}

.docLinkItem a {
  text-decoration: none !important;
  color: #D96320 !important;
}

.docLinkItem a:hover {
  color: #6A200C !important;

}

.docLinkItem:active {
  color: #882D00 !important;
}

.docListItemDetail { 
  font-weight: 400; 

}

.pageSubtitle { 
  margin-top: 1.5rem !important;
  margin-bottom: 1rem;

}

.pageInstructions{
  font-style: italic;

}

.pageLevel1Head {
  font-weight: 400;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  margin-top: 0rem;
}

.pageLevel2Head {
  text-transform: uppercase;
  font-weight: 600;
  color: #A8381A;
  font-size: 1rem;
  letter-spacing: .07rem;
  margin-bottom:1rem;
}

.pageLevel3Head {
  font-size: 1.1rem;
  font-weight: 500; 
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;

}

#defaultSections {
  border-bottom: 1pt dotted A8381A;
  padding-bottom: 2rem;

}
#newTemplate {
  margin-bottom: 2.5rem;
  color: #D96320 !important;
}

.sectionSidebar {
  height: "fit-content";
  margin-right: 0 !important;
  margin-top: 0rem !important;
  padding-top: 0rem !important;
  padding-right:2rem !important;
}

.intNav1 a{
  color: #692200;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0rem;
  font-weight: 400;
}

.intNav1 a:hover{
  color: #d96320;
}

.intNav1 a:active{
  font-weight: 800; 
}

#parentSectionsLabel {
  margin-top: 1rem;
}

.assembleForm { 
  padding: 1rem;
  text-transform: capitalize;
}

.form-check-input:checked {
  background-color: #CD6124 !important; /*MAIN*/
  border: none !important;
}

.form-check-input:not(:checked) {
  background-color: #FAFAFA; 
  border-color: #CD6124 !important;
}

.assembleForm .form-check-inline {
  color: black;

}

.intNavButton{ 
  background-color:#EF7956 !important;
  min-width: 20px;
  width: fit-content;
  border: none;
  margin-bottom: 1rem;
  padding: .5rem;
  font-size: .9rem;
  margin-left: 0 !important;
  text-align: left !important;

}

.intNavButton:hover,
.docSaveButton:hover,
.docSaveButton2:hover,
.dataAddInstance:hover{
  background-color: #6A200C !important;
}

.docEditSaveButton:hover{
  color: #6A200C !important;
}
#sectionsNextButton{
  margin-top:0.5rem;
}

.assembleSelected{
  display: none;
}

.docSupraSection{
  margin-bottom: 30px;

}

.docSection{
  background-color: #FAFAFA;
  width: 90%;
  padding-left: 0rem;
  padding-bottom: 1rem !important;
  padding-top: 0rem !important;
  margin-bottom: 1rem !important;
  border-color: #62462C;
  border-bottom: thin dotted #A82A11;
  font-weight: 600;
}

.synSection{
  display: inline-flex !important;
}

.docEditSection{
  margin-left: 0 !important;
  border-bottom: none !important;
}

.docSubSection{
  padding-left:2rem !important;
  border-left: thin dotted #FF9B54 !important;
}

.docCol1{
  margin: 0 !important;
  padding: 0 !important;
}

.docCol2{
  width: 100%;
  padding-right: 0 !important;
}

.liveSectionTitle{
  background-color: white !important;
  width: 88% !important;
  border: none !important;
  padding-left: 1rem !important;
  padding-right: .5rem !important;
  padding-top: .35rem !important;
  padding-bottom: .35rem !important;
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 0rem;
  margin-top: 0rem;
  float: right;
  margin-left: 0 !important
}

.docSaveButton{
  background-color:#919d95 !important;
  min-width: 20px;
  width: fit-content;
  border: none;
  margin-bottom: 0rem;
  margin-top: 0rem;
  padding: 2rem;
  margin-left: .5rem !important;
  margin-right: 0rem !important;
  text-align: left !important;
}

.docSaveButton2{
  background-color:#919d95 !important;
  min-width: 20px;
  width: fit-content;
  border: none;
  margin-bottom: 0rem;
  margin-top: 0rem;
  padding: 2rem;
  margin-left: 0rem !important;
  margin-right: 0.5rem !important;
  text-align: left !important;
}

.docEditSaveButton{
  font-weight: 400;
  color: black !important;
  font-size: .8rem !important;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  background-color: #FAFAFA !important;
  width: 100% !important;
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
  padding-left: 0 !important;
  margin-right: 1rem !important;
}

.docEditButton{
  color: #Ef7956 !important;
}

.docSectionTitle{
  margin-right: 3rem;
  font-weight: 500; 
  font-size: 1rem;
  text-transform: none;
  color: #62462C;
}

.docCardHead{
  color: black;
  font-size: 1rem !important;
  font-weight: 400 !important;
  background-color: #FAFAFA !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-bottom: dotted thin #A8381A !important;
}

.docCardBody{
  color:black;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.trashButton{
  margin-left:10;
  fill: #D96320 !important;
}

.trashButtonList{
  margin-left:2rem;
  color: #D96320 !important;
}

.docOrgButtons{
  margin-left:10;
  background-color:#F0F4F5;
  color: #D96320;
}

.docTextType{
  color:#374F53;
  font-size:.9rem;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
}

.editorBox{
  margin-top: 15px;
  margin-bottom: 15px;
}

.templateTextPreview{
  font-family: 'Times New Roman', Times, serif;
  margin-top: .5rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 145% !important;
}

.guidanceTextPreview{
  font-family: 'Times New Roman', Times, serif;
  margin-top: .5rem !important;
  font-style: italic;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 145% !important;
  color: black !important;
}

.dashTableHeadRow{
  border-bottom-color:#A8381A;
  border-bottom-style: dotted;
  margin-bottom: 0.5rem;
  padding-bottom: 0rem;
  margin-top: 4rem;
  width: 100% !important;
}

.dashHeadLabel{ 
  color: #6A200C;
  /* Body/Bold */
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
}

.dashHead{
  display: flex;
  width: 216px;
  padding: 8px 8px 9px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  background: var(--HitBox, rgba(255, 255, 255, 0.00));
}

.dashRowLabel{
  font-size: 1rem;
  color:#000000;
  font-weight: 400;
}

.dashRow{
  margin-top:5rem !important;
}

.welcomeText{
  color: #D96320;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%; 
  margin-right: 5rem !important;
}

.selected{
  font-weight: 800 !important;
  color: #D96320 !important;
}

.selected2{
  color: #A63C00 !important;
}

.topButtonGroup{
  display: inline-flex;
  justify-content: flex-end !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.dataGroup{
  color: black;
  font-size: 1rem;
  margin-bottom: 0.5rem !important;
}

.dataLabel{
  font-weight: 500;
  margin-bottom: 0 !important;
  padding-bottom: .35rem !important;
  margin-right: 1rem !important;
  color: #882D00 !important;
}

.dataGuidance{
  font-weight: 300;
  margin-bottom: 0.5rem !important;
  padding-bottom: 0 !important;
  color: black !important;
}

.dataLiveType {
  color:black !important;
  width: fit-content;
  max-width: 800px;
  font-size: 0.95rem !important;
  padding: 0.25rem !important;
  /*font-family: 'Times New Roman', Times, serif;*/
}

.dataText{
  text-transform: uppercase;
  font-size: .8rem !important;
  letter-spacing: 0.04 rem !important;
  line-height: 120% !important;
  color: slategray !important;
}

.dataCol{
  margin-bottom:.5rem;
}
.dataDrop{
  color:black;
  background-color: #f5f5f5 !important;
}

.dataExample{
  color: black !important;
  font-weight: 300; 
  font-size: .8rem !important;
}

.dataVarRow{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.dataAddInstance{
  background-color: #919d95 !important;
  font-size: .8rem !important;
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}

.synButtonGrp{
  width:30% !important;
  display:inline-flex;
  justify-content: flex-end;
}

.synEditBtn{
  margin-left: 1rem !important;
  padding-right: 0 !important;
  display: inline-flex !important;
  width: fit-content !important;
}

.synGroup{
  display: flex !important;
  padding-top: .5rem !important;
}

.synLabel{
  font-size: .9rem !important;
  margin-top: .35rem;
  margin-right: 2rem !important;
  width: 15% !important;

}

.editRow{
  margin-left: 0 !important;
}

.synLiveType{
  font-size: .9rem !important;
  padding-left: 1rem !important;
  font-weight: 400 !important;
}

.temporary{
  padding-left: 0 !important;
}

.temporary2{
  padding-right: 2rem !important;
  width: 15% !important;
}

.synPreview{
  margin-bottom:2rem !important;
}

.saveInstructions{
  color: black;
  font-size: .85rem !important;
  font-weight: 300 !important;
  margin-bottom: 2rem !important;
}

.draftLabel{
  font-weight: 600;
  display: none !important;
}

.saveTempBtn{
  font-size: .9rem !important;
  vertical-align: center !important;
  background-color: #EF7956 !important;
}

.saveLive{
  margin-bottom: 1rem !important;
}

.varT{
  color: black;
}

.varTb{
  color: black;
}

.varR{
  color: black;
}

.varD{
  color: black;
}

.tbl{
  background-color:#FAFAFA;
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.tblBody{
  color:black;
}

.tblR{
  color: black;
}

.tblH{
  color: black;
}

.tblD{
  color: black;
}

.tblLiveType{
  color: black;
}

.varInstrux{
  color: black;
  font-weight: 400;
  line-height: 140%;
  margin-bottom:.5rem !important;

}

.varTempBtn{
  font-size: .9rem !important;
  vertical-align: center !important;
  margin-right: .75rem !important;
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

.varBody{
  color: black;
}

.warnBtn{
  background-color:#919d95 !important;
  margin-top: 1rem !important;
  width: fit-content !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.variant{
  color:white;
  margin-top: 0 !important;
  width: fit-content !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.accBody{
  background-color: #FAFAFA !important;
  padding: 1rem !important;
  margin: 0 !important;
}

.varHead{
  margin-bottom: .5rem;
  margin-top: 1rem !important;
}

.accHead{
  margin-bottom: 1rem !important;
}

.tempVarBtn{
  justify-content: flex-end !important;
  display:inline-flex !important;
}

.varRow{
  margin: 0 !important;
}

.deleteCheck{
  font-weight:400;
  font-size: .9rem;
  padding: 0;
  padding-top:.5rem;
  margin: 0;
  line-height: 140%;
}

.loginCont{
  background: url('./img/Asclepia_Login_Background.png'), lightgray 50% / cover no-repeat;
  padding: 0 !important;
}

.formLogin{
  padding: 0rem;
  padding-bottom: 3rem;
  margin-top: 0rem !important;

}

.logoRow{
  height: fit-content;
  display: inline-flex !important;
  margin-bottom: 0 !important;
  padding-bottom: 0rem !important;
}

.formLogin{
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.login-container{
  margin-top: 25vh !important;
  min-height: 25vh !important;
  height: fit-content;
  margin-left: 25vw !important;
  margin-right: 25vw !important;
  padding: 2rem !important;
  display: block !important;
  border-radius: 14px !important;
  
  
}

.screen{
  background-color: rgba(0,0,0,0.15);
  width: 100%;
  margin: 0 !important;
  height: 100% !important;
}

.loginHead{
  font-size: 1.25rem !important;
  line-height: 1.7rem;
  margin-bottom: 2rem !important;
  font-weight: 400; 
}

.logoRow{
  width: 100% !important;
  margin-bottom: 2rem !important;
}

.confirm-code-shell{
  margin-bottom: 2rem !important;
}

.conBtn{
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.docTextBlock{
  display: inline-flex;
  margin-top:.5rem !important;
}

.proto{
  margin-top:2rem !important;
}

.notifyCard{
  flex-shrink: 0;
  border-radius: 4px;
  background-color: #F3F3F6 !important;
  /* Small Shadow */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 2rem;
}

.notifyEntry{
  padding-top: .5rem;
  padding-bottom: .25rem;
}

.notifyBody{
  color: black;
  background-color: #F3F3F6 !important;
}

.notifyHead{
  color: black;
}

.notifyLabel{
  color: #6A200C;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.notifyRead{
  color: #1A1A1A;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.notifyName{
  font-weight: 500;
}

tr{
  background: rgba(255, 255, 255, 0.00) !important; 
}

.dashTable{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.dashBar{
  opacity: .5 !important;
}

.dashboardTable{
  background-color: #F3F3F6;
  border-radius: 4px;
  padding: 2rem !important;
}



/* Rebrand */
/* Core styles to move to scss */
.kinetikaOrange{
  color: #D96320 !important;
}

.nav-link, .btn-link {
  color: #D96320 !important;
}
.nav-link:hover,  .btn-link:hover {
  color: #A63C00 !important;
}
.nav-link:active, .btn-link:active {
  color: darkslateblue !important;
}

/* Experimental styles */
.dark-modal .modal-content {
  background-color: rgb(19, 8, 8);

}

.dark-backdrop {
  opacity: 0.8 !important;
  background-color: darkslategray;
}

/* EditVariable.css */
.button-link {
  color: #f0b38f !important;
  font-size: 1rem;
  line-height: 1;
}

.button-cancel-dark, .button-save-light {
  background-color: black !important;
  color: #f0b38f !important;
  border: 1px solid #f0b38f;
}

.button-save-dark, .button-cancel-light {
  background-color: #f0b38f !important;
  color: black !important;
  border: 1px solid black;
}

.button-save-dark, .button-cancel-dark, .button-save-light, .button-cancel-light {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 500;
  border-radius: 50px !important;
  font-size: 90% !important;

}

p {
line-height: 1.85; /* 1.5 line height for paragraphs */
}

.label-style-light {
  color: #A63C00;  
}

.label-style-dark {
  color: #f0b38f;  
}

.label-style-light, .label-style-dark {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 500;
  font-style: regular;
  font-size: 90% !important;   
}

.modal-form-title {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 300 !important;
  color: rgb(223, 211, 200);
  font-size: 210% !important; 

}

.modal-form-heading {
  font-size: 150% !important;
  font-weight: 300 !important;
  color: whitesmoke;
  text-align: left;
}

.form-control-dark, .form-control-light {
  background-color: rgb(158, 158, 158) !important;
  color: black !important; /* Ensure text is visible on dark background */
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 500;
  border: none !important;
  padding: .5rem !important;
  font-size: 88% !important; 
  border-radius: 0 !important; /* Remove rounded edges */
}

.form-control-dark:disabled, .form-control-light:disabled {
  color: whitesmoke !important; /* Set the desired color for disabled state */
}

.modal-footer {
  border-top: none !important;
}

.rounded-square {
  border-radius: 10% !important;
  padding: 2px 0px 2px 4px !important; /* Adjust padding to control the size of the circle */
}

.compliment {
  background-color: rgb(254, 244, 244) !important;
}

.kOrangeBgd {
  background-color: #f99c6d !important;
}

.pill {
  border-radius: 40px;
  padding: 5px 15px;
  margin-bottom: 5px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevents content from overflowing */
  text-overflow: ellipsis; /* Adds ellipsis (...) for overflowing text */
  white-space: nowrap; /* Prevents text from wrapping to the next line */

}

.blinking-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #333;
  border-radius: 50%;
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0%, 100% {
      opacity: 0.2;
  }
  50% {
      opacity: 1;
  }
}

.listBlockWrapper {
  border: 2px solid #D96320; /* Change border color to salmon */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Some padding inside */
  margin-bottom: 15px; /* Space between variable sets */
  background-color: #fbf8f8; /* Optional: background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
}

.placeholder-with-hash {
  position: relative;
  padding-left: 20px; /* Adjust as needed */
}

.placeholder-with-hash::before {
  content: '#';
  position: absolute;
  left: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  color: #ccc; /* Ghostly color */
  pointer-events: none;
}

/* Add any additional styles needed for the EditTemplate component here */

.modal-form-title {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 300 !important;
  color: rgb(223, 211, 200);
  font-size: 210% !important; 
}

.form-control-dark, .form-control-light {
  background-color: rgb(158, 158, 158) !important;
  color: black !important;
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 500;
  border: none !important;
  padding: .5rem !important;
  font-size: 88% !important; 
  border-radius: 0 !important;
}

.button-link {
  color: #f0b38f !important;
  font-size: 1rem;
  line-height: 1;
}

.button-cancel-dark, .button-save-light {
  background-color: black !important;
  color: #f0b38f !important;
  border: 1px solid #f0b38f;
}

.button-save-dark, .button-cancel-light {
  background-color: #f0b38f !important;
  color: black !important;
  border: 1px solid black;
}

.button-save-dark, .button-cancel-dark, .button-save-light, .button-cancel-light {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 500;
  border-radius: 50px !important;
  font-size: 90% !important;
}

.label-style-light, .label-style-dark {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 500;
  font-style: regular;
  font-size: 90% !important;   
}

.placeholder-with-hash {
  position: relative;
  padding-left: 20px;
}

.placeholder-with-hash::before {
  content: '#';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  pointer-events: none;
}
