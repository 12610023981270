@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body, #root, .container-fluid, .row {
  height: calc(100%)
}

#root { width: 100%; }

.h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 2rem !important;

}
.leftSide {
  background: linear-gradient(135deg, #F07167 36.46%, #FF9B54 100%);
  height: 100%;
  min-height: calc(100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  display: flex;
  background-color: #f5f5f5;
  min-height: 100%;
}

.form-login {
  width: 100%;
  max-width: 100%;
  padding: 0 16vw 0 2vw;
  margin: auto;
}

body {
  font-family: 'Roboto', sans-serif !important;
  font-size: 1em !important;
  font-weight: 400 !important;
  line-height: 1.25 !important;
  letter-spacing: 0.15px;
  
}

.form-control {
  padding: 0 .75rem 0 .75rem !important;
  overflow: hidden !important;
}

.form-login .form-floating:focus-within {
  z-index: 2;
}

.form-login .checkbox {
  font-weight: 400;
}

.log-in-button {
  width: 100% !important;
  font-family: 'Roboto', sans-serif;
  font-size: .95em !important;
  font-weight: 500 !important;
  letter-spacing: 0.46px !important;
  text-transform: uppercase;
  min-height: 3em;
  box-shadow: 0px 1px 5px 0px #0000001F;
 /* box-shadow: 0px 2px 2px 0px #00000024;
  box-shadow: 0px 3px 1px -2px #00000033;*/


}

.log-in-button-disabled {
  background-color:black !important;
 /* box-shadow: 0px 2px 2px 0px #00000024;
  box-shadow: 0px 3px 1px -2px #00000033;*/


}
.log-in-button:focus{
  border-color: transparent !important;
}

.login-alert {
  border-radius: 4px !important;
  margin-bottom: 3em !important;
  font-size: .85em !important;
  line-height: 1.5em !important;

}

.confirm-code {
  font-family: 'Roboto',sans-serif;
  font-size: .9em !important;
  text-align: center;
  background-color:transparent !important;
  border-color: transparent !important;
  color: black;

}

.confirm-code>button{
  color: #F07167 !important;
  font-size:1.1em !important;
  text-decoration-thickness: 1px !important;
  padding: 0 !important;
}

.confirm-code>button:hover{
  color: darkred !important;
}


.confirm-code>button:focus{
  font-weight: 400;
  color: rebeccapurple !important;
}

.confirm-code>button:focus,
.confirm-code>button:hover,
.confirm-code>button:active,
.confirm-code>button:enabled,
.btn:focus, .btn:hover, .btn:active, .btn:enabled{
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
#confirm-screen{
  transform: translateY(3rem);
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: 100% !important;
  color: black !important;
  transform: scale(.75) translateY(-0.7rem) translateX(1rem) !important;
  background-color: #f5f5f5;
  padding: .1rem !important;
  max-height: 1.5rem;

}

.form-floating>.form-control:placeholder-shown~label {
  padding: .75rem !important;
  transform: scale(1) translateY(.3rem) !important;
  background-color: transparent !important;

}

.form-control {
  border-color: darkgray !important;
  background-color: #f5f5f5 !important;

}

.form-control:focus,
.form-control:-internal-autofill-selected,
.form-control:not(:placeholder-shown) { 
  box-shadow: none !important;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover, 
.form-control:-webkit-autofill:focus, 
.form-control:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
}

/* To Fix: 
Currently, the loaded autofill text is smaller than the text appears when you put your cursor in the email
field. I am putting a pin in this for now, because I don't see it as a big deal - but it's not a feature 
of Angelina's design, so will come back to it if it bothers anyone.
*/
/* To Fix: 
I also haven't matched transition styles from Angelina's designs, but will leave them as is for now & adjust 
going forward. */ 